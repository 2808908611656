.status-pill {
    @apply rounded-full px-3  font-medium;
    &.status-0 {
        /* draft */
        @apply bg-amber-100 text-amber-800;
    }
    &.status-1 {
        /* published */
        @apply bg-emerald-100 text-emerald-800;
    }
}
