.ant-slider {
    &-rail {
        @apply bg-gray-300 rounded-none;
        height: 2px;
    }
    &:hover {
        .ant-slider-rail{
            @apply bg-gray-300;
        }
        .ant-slider-handle {
            @apply border-indigo-400;
        }
    }
    &-track {
        @apply hidden;
    }
    &-dot, &-dot-active {
        @apply bg-gray-400 border-0;
        width: 1px;
        height: 12px;
        background-color: bg-gray-400;
        top: -5px;
        &:nth-child(even) {
            height: 8px;
            top: -3px;
        }
    }
    &-handle {
        @apply border-indigo-400;
        width: 16px;
        height: 16px;
        margin-top: -7px;
    }
}
