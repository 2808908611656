/* @see https://tilomitra.github.io/infinite/ */
@keyframes pulsate {
    0% {transform: scale(.1, .1); opacity: .0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: .0;}
}

/* button */
.btn {
    @apply text-sm leading-5 font-medium text-center;
    @apply transition inline-block no-underline rounded-none border border-solid border-transparent py-2 px-4 cursor-pointer;

    &.block {
        display: block;
    }

    &.btn-sm {
        @apply text-sm leading-4 font-medium;
    }
    &.btn-xs {
        @apply text-xs leading-4 font-medium;
    }

    &.focus,
    &:focus {
        @apply shadow-none outline-none ring-2 ring-offset-2;
        box-shadow: none;
    }

    &.hover,
    &:hover {
        @apply outline-none shadow-sm;
        box-shadow: none;
    }

    &.disabled,
    &[disabled] {
        @apply relative opacity-60 pointer-events-none;
    }

    &.loading {
        @apply relative text-transparent focus:text-transparent hover:text-transparent overflow-hidden pointer-events-none;

        &::before {
            @apply absolute inset-0 m-auto h-5 w-5 border-4 border-white border-solid rounded-full;
            animation: pulsate 1s ease-out;
            animation-iteration-count: infinite;
            content: '';
        }
    }

    &.icon-only {
        @apply px-2;
    }

    svg,
    i {
        @apply inline-block leading-5 w-5;
    }
}

/* button group */
.btn-group {
    @apply inline-flex;

    .btn {
        &.focus,
        &:focus {
            @apply z-10;
        }
    }

    .btn-rounded {
        &:first-child {
            @apply rounded-r-none;
        }

        &:last-child {
            @apply rounded-l-none;
        }
    }
}

/* rounded */
.btn-rounded {
    @apply rounded-md;
}

/* primary */
.btn-primary {
    @apply bg-indigo-600 border-indigo-600 text-white;

    &.hover,
    &:hover {
        @apply bg-indigo-700;
    }

    &.focus,
    &:focus {
        @apply ring-2 ring-offset-2 ring-indigo-500;
    }

    svg,
    i {
        fill: theme('colors.white');
    }
}

/* secondary */
.btn-secondary {
    @apply bg-white border-gray-300 text-gray-500;

    &.hover,
    &:hover {
        @apply bg-gray-50;
    }

    &.focus,
    &:focus {
        @apply ring-2 ring-offset-2 ring-gray-400;
    }

    &.active {
        @apply bg-indigo-600 border-indigo-600 text-white;

        svg,
        i {
            fill: theme('colors.white');
        }
    }

    svg,
    i {
        fill: theme('colors.gray.500');
    }
}


/* light */
.btn-light {
    @apply bg-indigo-50 border-indigo-200 text-indigo-600;

    &.hover,
    &:hover {
        @apply bg-indigo-200;
    }

    &.focus,
    &:focus {
        @apply ring-2 ring-offset-2 ring-indigo-200;
    }

    svg,
    i {
        fill: theme('colors.indigo.600');
    }
}


/* danger */
.btn-danger {
    @apply bg-red-600 border-red-600 text-white;

    &.hover,
    &:hover {
        @apply bg-red-700;
    }

    &.focus,
    &:focus {
        @apply ring-2 ring-offset-2 ring-red-500;
    }

    svg,
    i {
        fill: theme('colors.white');
    }
}
.btn-danger-light {
    @apply bg-indigo-50 border-red-100 text-red-600;

    &.hover,
    &:hover {
        @apply bg-red-600 text-white;
    }

    &.focus,
    &:focus {
        @apply ring-2 ring-offset-2 ring-red-500;
    }

    svg,
    i {
        fill: theme('colors.white');
    }
}

/* button toggle */
.btn-toggle {
    svg,
    i {
        &:first-child {
            @apply block;
        }
        &:last-child {
            @apply hidden;
        }
    }

    &.active {
        svg,
        i {
            fill: theme('colors.white');

            &:first-child {
                @apply hidden;
            }

            &:last-child {
                @apply block;
            }
        }
    }
}
