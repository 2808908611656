.ant-upload{
    &.ant-upload-drag {
        @apply border-2 rounded-md border-dashed border-gray-300 bg-transparent mb-4;
    }
    &.ant-upload-disabled{
        display: none;
    }
}
.ant-upload-list{
    &.ant-upload-list-picture-card{
        @apply grid gap-4 md:grid-cols-6 grid-cols-2;
        &::before {
            display: none;
        }
        .ant-upload-list-picture-card-container {
            margin: 0;
            width: 100%;
            height: auto;
            aspect-ratio: 1;

        }
        .ant-upload-list-item-actions{
            @apply flex flex-row flex-nowrap gap-4 items-center;
            .anticon-eye,
            .anticon-delete,
            .anticon-download
            {
                @apply m-0;
                font-size: 24px;
                width: 24px;
            }
        }
    }
}

/*
.ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
}
.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error {
    @apply rounded-md p-0;
}*/

