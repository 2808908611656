/* steps wrapper */


/* step */
.stepper{
    &__step {
        @apply pl-4 py-2 flex flex-col border-l-4 border-gray-200;
        
        &__title {
            @apply text-lg text-gray-500 font-bold tracking-wide uppercase group-hover:text-indigo-800;
        }
        &__subtitle {
            @apply text-sm font-medium;
        }
        &.valid,
        &.active {
            @apply border-indigo-600;
            .stepper__step__title{
                @apply text-indigo-600;
            }
        }
    }

    
}
@media screen(md){
    .stepper__step {
        @apply pl-0 pt-4 pb-0 border-l-0 border-t-4;
    }
}
