.expanded-row {
    @apply flex gap-8 bg-gray-100 rounded mb-1 flex-nowrap print:block items-start px-4 py-2;
    .expanded-row__title {
        @apply text-sm font-medium print:w-full print:underline ;
        width: 100px;
    }
    .expanded-row__content {
        @apply flex gap-x-12 print:flex-wrap print:gap-y-2;
        .item {
            @apply flex flex-nowrap text-sm leading-5 print:min-w-[25%] ;
            .title {
                @apply text-gray-500 mr-2 whitespace-nowrap;
            }
            .value {
                @apply text-gray-900;
                //white-space: nowrap;
            }
        }
    }
}
