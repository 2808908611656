.form-checkbox,
.form-input,
.form-multiselect,
.form-radio,
.form-select,
.form-static,
.form-textarea {
    @apply border-gray-300 rounded-md shadow-sm text-sm w-full inline-block px-3 py-2;
    @apply focus:border-indigo-300 focus:ring-indigo-200 focus:ring focus:ring-opacity-50 focus:shadow-none;
    @apply hover:shadow-none;

    /* see javascript */
    &.dirty:invalid {
        @apply bg-red-100 border-2 border-red-500;
    }

    &.disabled,
    &[disabled] {
        @apply border-gray-200 bg-gray-200 text-gray-900 pointer-events-none;
        @apply focus:border-gray-200 focus:bg-gray-200 focus:text-gray-900 focus:ring-0;
    }

    &.invalid,
    &:invalid {
        @apply bg-red-100 border-2 border-red-500;
    }

    /*&:read-only,*/
    &.readonly,
    &[readonly] {
        @apply border-indigo-200 bg-indigo-200 text-indigo-600 pointer-events-none;
        @apply focus:ring-0 focus:border-indigo-200;
    }

    input, select, textarea {
        @apply border-0 shadow-none flex-grow min-w-0 block;
    }
}

.input-group {
    @apply flex flex-nowrap border rounded-md shadow-sm mb-2 w-full overflow-auto;
    @apply border-gray-300 rounded-md shadow-sm text-sm;
    @apply focus-within:border-indigo-300 focus-within:ring-indigo-200 focus-within:ring focus-within:ring-opacity-50 focus-within:shadow-none;
    @apply hover:shadow-none;

    /* see javascript */
    &.dirty {
        @apply bg-red-100 border-red-500 border-2;

        div,
        input,
        select,
        span,
        textarea {
            @apply bg-red-100;
        }
    }

    &.disabled {
        @apply bg-gray-200 border-gray-200 text-gray-900 pointer-events-none;
        @apply focus-within:border-gray-200 focus-within:bg-gray-200 focus-within:text-gray-900 focus-within:ring-0;

        div,
        input,
        select,
        span,
        textarea {
            @apply bg-gray-200 text-gray-900;
        }
    }

    &.invalid {
        @apply bg-red-100 border-red-500 border-2;

        div,
        input,
        select,
        span,
        textarea {
            @apply bg-red-100;
        }
    }

    &.readonly {
        @apply bg-indigo-200 border-indigo-200 pointer-events-none;
        @apply focus-within:ring-0 focus-within:border-indigo-200;

        div,
        input,
        select,
        span,
        textarea {
            @apply bg-indigo-200 text-indigo-600;
        }
    }

    > span {
        @apply bg-transparent inline-flex items-center px-3 sm:text-sm whitespace-nowrap;

        &:first-child {
            @apply rounded-l-md;
        }

        &:last-child {
            @apply rounded-r-md;
        }
    }

    > div,
    > input,
    > select,
    > textarea {
        @apply border-0 shadow-none block flex-grow min-w-0 px-3 py-2 h-10 text-sm overflow-auto;

        &:focus {
            box-shadow: none !important;
        }
    }

    > span + div,
    > span + input,
    > span + select,
    > span + textarea {}
}

.input-group {
    input:focus {
        @apply shadow-none;
    }
}


.fields-square {
    .form-checkbox,
    .form-input,
    .form-multiselect,
    .form-radio,
    .form-select,
    .form-static,
    .form-textarea {
        @apply bg-gray-100 border-0 rounded-none h-7 px-2 py-1;
    }

    .form-select {
        @apply py-0;
    }

    .input-group {
        @apply bg-gray-100 border-0 rounded-none h-7;

        div,
        input,
        select,
        span,
        textarea {
            @apply h-7 px-2 py-1 text-sm;
        }

        &:not(.readonly):not(.disabled) {
            @apply bg-gray-100;

            div,
            input,
            select,
            span,
            textarea {
                @apply bg-gray-100 text-gray-500;
            }
        }
    }
}

.formset {
    @apply bg-white shadow rounded sm:rounded-lg p-6 pt-2;

    h5 {
        @apply text-gray-900 font-normal text-xl pt-4 mb-3;
    }

    .form-group {
        @apply mb-0 border-gray-200 border-b py-4;

        &:last-child {
            @apply border-b-0 pb-0;
        }

        input {
            @apply w-full;
        }

        label {
            @apply mr-4;
            min-width: 33.3%;
            max-width: 33.3%;
        }
    }

    label {
        @apply font-normal text-sm;
    }

}

.form-section {
    @apply mb-10;
}

.form-section__title {
    @apply mb-6;
    span {
        @apply font-bold text-2xl;
    }

    i {
        @apply text-indigo-600 text-xl;
    }
}



