/*Form fields*/
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
    color: #4a5568 !important; /*text-gray-700*/
    padding-left: 1rem !important; /*pl-4*/
    padding-right: 1rem !important; /*pl-4*/
    padding-top: .5rem !important; /*pl-2*/
    padding-bottom: .5rem !important; /*pl-2*/
    line-height: 1.25 !important; /*leading-tight*/
    border-width: 2px !important; /*border-2*/
    border-radius: .25rem !important;
    border-color: #edf2f7 !important; /*border-gray-200*/
    background-color: #edf2f7 !important; /*bg-gray-200*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
    background-color: #ebf4ff !important; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate {
    @apply py-1 text-center;
    float: unset;

}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    @apply btn btn-rounded btn-light btn-xs mx-0.5;

    &.current {
        @apply outline-none shadow-sm;
        @apply bg-indigo-200;
        box-shadow: none;
    }
}

table.dataTable td.dataTables_empty {
    @apply font-bold text-gray-300 align-middle;
}

/*Add padding to bottom border */
table.dataTable.no-footer {
    border-bottom: 1px solid #e2e8f0 !important; /*border-b-1 border-gray-300*/
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    background-color: #667eea !important; /*bg-indigo-500*/
}

table.dataTable tbody tr.selected {
    background-color: #FAFAFF;
}



.dataTables_wrapper {
    @apply border-gray-200;
}

.dataTables_wrapper table.dataTable {
    min-height: 100px;
}
.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td {
    @apply text-gray-500 border-0 border-b border-gray-200 font-medium text-xs uppercase text-left;
    background-color: #f9fafb;
}
.dataTables_wrapper table.dataTable tbody {
    @apply min-h-full;
}
.dataTables_wrapper table.dataTable tbody th,
.dataTables_wrapper table.dataTable tbody td {
    @apply border-0 border-b border-gray-200 px-3 py-4;
}


