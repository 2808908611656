@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body, p {
    @apply text-base leading-6 font-normal;
}

small {
    @apply text-sm leading-5 font-normal;
}

strong {
    @apply text-lg leading-7 font-normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
    @apply font-extrabold;
}

h1, .h1 {
    @apply text-6xl leading-none;
}
h2, .h2 {
    @apply text-4xl leading-10;
}
h3, .h3 {
    @apply text-3xl leading-9;
}
h4, .h4 {
    @apply text-2xl leading-8;
}
h5, .h5 {
    @apply text-xl leading-7;
}
h6, .h6 {
    @apply text-lg leading-7;
}

a {}

.link-hover {
    @apply inline-block no-underline relative transition-colors;

    &::after {
        @apply opacity-0 transition-opacity;
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        content: '\f0a9';
        width: 1.25em;
    }

    &:focus,
    &:hover {
        @apply text-indigo-500;

        &::after {
            @apply opacity-100;
        }
    }

    &:focus {}
    &:hover {}
}
