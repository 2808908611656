@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@fortawesome/fontawesome-pro/css/all.css';
@import './plugins/jquery.dataTables.css';

/* Common */
@import './common/_typography.css';

/* Components */
@import './components/_background.css';
@import './components/_button.css';
@import './components/_datatable.css';
@import './components/_stepper.css';
@import './components/_form.css';
@import './components/_footer.css';
@import './components/_peb.css';
@import './components/_status.css';
@import './components/_upload.css';
@import './components/_expanded_place.css';
@import './components/_filter_modal.css';
@import './components/_slider.css';

/* Layouts */
